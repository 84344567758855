import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from 'gatsby-image';
import useWindowSize from '../hooks/useWindowSize'


const SlicesAwards = ({ posts }) => {
  
  if (!posts) return null
  return (
    <div className="awardImages">
      {posts.node.body.map((slice, index) => {
          
          if(slice.type === "award_image"){
            return (
                <Img loading="lazy" className="awardImage" key={slice.primary.award_imageSharp.childImageSharp.id} fluid={slice.primary.award_imageSharp.childImageSharp.fluid} alt={slice.primary.award_image.alt}/>
            )
          }
          else{
            return(null)
          }

      })}
    </div>
  )
}

const SlicesInfo = ({ posts }) => {
  const { width } = useWindowSize();
  if (!posts) return null
  return (
    <div className="allProductInfo">
      {posts.node.body.map((slice, index) => {
          if(slice.type === "product_info"){
            return (
              <div className="productInfo" key={index}>
                <h1 className="infoHeader">{RichText.asText(slice.primary.info_title)}</h1>
                <p className="infoP" >{RichText.asText(slice.primary.info_text)}</p>
                <Img loading="lazy" className="infoImage" fluid={width > 600 ? slice.primary.d_info_imageSharp.childImageSharp.fluid : slice.primary.m_info_imageSharp.childImageSharp.fluid} alt={slice.primary.info_image.alt}/>
              </div>
            )
          }
          else{
            return(null)
          }

      })}
    </div>
  )
}

export default ({ data }) => {
  const doc = data.prismic.allProducts.edges.slice(0, 1).pop()
  if (!doc) return null
  return (
    <Layout>
      <SEO
        title={RichText.asText(doc.node.name)}
        image={doc.node.top_picture.url+"&w=1200"}
      />
      <div className="topImageCont" >
        <Img className="topImage" loading="eager" fadeIn durationFadeIn={2000} fluid={doc.node.top_pictureSharp.childImageSharp.fluid} key={doc.node.top_pictureSharp.childImageSharp.id} alt={doc.node.top_picture.alt}/>
      </div>
      <div className="productImageCont"> 
        <Img fadeIn className="productImage" loading="eager" durationFadeIn={4000} key={doc.node.m_product_pictureSharp.childImageSharp.id} fluid={doc.node.m_product_pictureSharp.childImageSharp.fluid} alt={doc.node.product_picture.alt}/>
      </div>
      <SlicesAwards posts={doc}/>
      <div className="contentAfterImage">
        <div>
          {doc.node.registration.map((paragraph, index) => {
            return(
              <p key={index} className="registration">{paragraph.text}</p>
            )
          })}
        </div>
        <div className="bottleInfoAndTitle">
          <h1 className="otherTitles">{RichText.asText(doc.node.product_heading)}<span className="dot">.</span></h1>
          <img loading="lazy" className="bottleInfo" src={doc.node.product_explain_image.url} alt={doc.node.product_explain_image.alt}/>
        </div>
        <SlicesInfo posts={doc}/>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ProductsQuery($uid: String) {
    prismic {
      allProducts(uid: $uid) {
        edges {
          node {
            name
            product_heading
            _meta {
              id
            }
            registration
            body {
              ... on PRISMIC_ProductBodyProduct_info {
                type
                primary {
                  info_text
                  info_title
                  info_image
                  d_info_imageSharp: info_imageSharp{
                    childImageSharp{
                      id
                      fluid(maxWidth: 100, quality: 100){
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      }
                    }
                  }
                  m_info_imageSharp: info_imageSharp{
                    childImageSharp{
                      id
                      fluid(maxWidth: 69, quality: 100){
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_ProductBodyAward_image {
                type
                label
                primary {
                  award_image
                  award_imageSharp {
                    childImageSharp {
                      id
                      fluid(maxWidth: 130, quality: 100){
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      }
                    }
                  }
                }
              }
            }
            product_explain_image
            top_picture
            top_pictureSharp {
              childImageSharp {
                id
                fluid (maxWidth: 3000, quality: 100) {
                  
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            product_picture
            m_product_pictureSharp: product_pictureSharp {
              childImageSharp {
                id
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`